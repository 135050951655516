<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align:left">
                <span>人员列表</span>
            </div>
        </template>
        <div class="content">
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24"   style="text-align:left;">
                        <div class="searchblock">
                            <el-select placeholder="账号所属" v-model="type" size="small" style="width:100px;margin-right:10px;" @change="GetRoleList(type)" v-if="ismerchant == 0">
                                <el-option label="运维端" value="1"></el-option>
                                <el-option label="商户端" value="2"></el-option>
                            </el-select>
                            <el-select placeholder="商户名称" v-model="merchantid" size="small" style="width:200px;margin-right:10px;" filterable clearable v-if="type == 2">
                                <el-option v-for="merchant in merchantlist" :key="merchant" :value="merchant.key" :label="merchant.label"></el-option>
                            </el-select>
                            <el-select placeholder="账号角色" v-model="roleid" clearable size="small" style="width:100px;">
                                <el-option v-for="role in roles" :key="role" :value="role.ID" :label="role.Role"></el-option>
                            </el-select>
                            <el-input v-model="keyword" placeholder="请输入账号名或者手机号" style="width:200px;margin-left:10px;" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                            <el-button type="success" @click="CreateAccount" style="margin-left:10px;" class="hidden-sm-and-down" size="small">创建员工账号</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                >
                
                <el-table-column type="index" width="80" label="序号"></el-table-column>
                <el-table-column prop="Merchant" label="所属商户">
                    <template #default='scope'>
                        <span v-if="!scope.row.Merchant">深圳市闲牛科技有限公司</span>
                        <span v-else>{{scope.row.Merchant}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="OperatorName" label="账号名"></el-table-column>
                <el-table-column prop="OperatorMobile" label="手机号"></el-table-column>
                <el-table-column prop="Role" label="账号角色">

                </el-table-column>
                <el-table-column prop="AddDTime" :formatter="Getdate" label="创建时间"></el-table-column>
                <el-table-column label="状态">
                    <template #default="scope">
                        
                        <el-switch v-model="scope.row.Status" active-value="1" inactive-value="0" @change="UpdateStatus(scope.row.ID,scope.row.Status,scope.$index)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button type="text" @click="ResetPassword(scope.row.ID)">修改密码</el-button>
                        <el-button type="text" @click="Edit(scope.row.ID)">编辑资料</el-button>
                        <el-button type="text" @click="Del(scope.row.ID)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
        </div>
    </el-card>
    
    <el-dialog v-model="password" title="修改密码" center width="30%">
        <el-form :model="passwordinfo" label-width="100px" ref="passwordinfo" :rules="passwordrule">
            <el-form-item label="新密码" prop="newpassword" required>
                <el-input v-model="passwordinfo.newpassword"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmpassword" required>
                <el-input v-model="passwordinfo.confirmpassword"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="SavePassword">确定</el-button>
            <el-button size="small" @click="ClosePassword">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="edit" title="编辑资料" center width="30%">
        
        <el-form :model="operatorinfo" label-width="100px" ref="operatorinfo" :rules="oprules" size="small">
            <el-form-item>
                <el-tag>
                    <i class="el-icon-warning"></i>
                    手机号为登录账号，密码默认为手机号后6位
                </el-tag>
            </el-form-item>
            <el-form-item label="手机号:" prop="OperatorMobile" required>
                <el-input v-model="operatorinfo.OperatorMobile" :disabled="operatorinfo.ID?true:false"></el-input>
            </el-form-item>
            <el-form-item label="账号名:" prop="OperatorName" required>
                <el-input v-model="operatorinfo.OperatorName"></el-input>
            </el-form-item>
            <el-form-item label="所属端:" prop="Type" required v-if="ismerchant != 1">
                <el-radio-group v-model="operatorinfo.Type" :disabled="operatorinfo.ID?true:false" @change="GetRoleList(operatorinfo.Type)">
                    <el-radio-button label="1">运维端</el-radio-button>
                    <el-radio-button label="2">商户端</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="所属商户:" prop="MerchantID" v-if="operatorinfo.Type == 2" :required="operatorinfo.Type == 2?true:false">
                <el-select v-model="operatorinfo.MerchantID" size="small" filterable :disabled="operatorinfo.ID?true:false">
                    <el-option v-for="merchant in merchantlist" :key="merchant" :value="merchant.key" :label="merchant.label"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="账号角色:" prop="RoleIDs" required>
                <el-select v-model="operatorinfo.RoleIDs" placeholder="请选择员工角色" multiple>
                    <el-option v-for="role in roles" :key="role" :value="role.ID" :label="role.Role"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="SaveEdit">确定</el-button>
            <el-button size="small" @click="CloseEdit">取消</el-button>
        </template>
    </el-dialog>
</template>
<style scoped>

</style>
<script>
import Cookies from 'js-cookie'
import qs from "qs"
import constant from "@/constant"
export default {
    data(){
        return{
            load:false,
            ismerchant:'',
            mid:'',
            curpage:1,
            pagesize:10,
            totalcount:0,
            merchantid:'',
            merchantlist:[],
            roleid:'',
            keyword:'',
            roles:[],
            tabledata:[],
            password:false,
            edit:false,
            roletype:'',
            type:'1',
            operatorinfo:{
                ID:'',
                OperatorMobile:'',
                OperatorName:'',
                RoleIDs:[],
                MerchantID:'',
                Type:'1'
            },
            passwordinfo:{
                id:'',
                newpassword:'',
                confirmpassword:'',
            },
            oprules:{
                OperatorMobile:[{
                    required:true,
                    message:'请填写手机号',
                    trigger:'blur',
                }],
                OperatorName:[{
                    required:true,
                    message:'请填写账户名',
                    trigger:'blur',
                }],
                RoleIDs:[{
                    required:true,
                    message:'请选择角色类型',
                    trigger:'blur',
                }],
                MerchantID:[{
                    required:true,
                    message:'请选择商户',
                    trigger:'blur',
                }]
            },
            passwordrule:{
                newpassword:[{
                    required:true,
                    message:'请填写新密码',
                    trigger:'blur'
                }],
                confirmpassword:[{
                    required:true,
                    message:'请确认新密码',
                    trigger:'blur'
                }]
            }
        }
    },
    methods:{
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        CloseEdit(){
            this.edit = false;
        },
        ResetPassword(id){
            this.passwordinfo.id = id;
            this.password = true;
        },
        ClosePassword(){
            this.password = false;
        },
        CreateAccount(){
            this.GetRoleList(1);
            this.operatorinfo = {
                ID:'',
                OperatorMobile:'',
                OperatorName:'',
                RoleIDs:[],
                MerchantID:'',
                Type:'1'
            },
            this.edit = true;
        },
        SaveEdit(){
            this.$refs['operatorinfo'].validate((valid)=>{
                if(valid){
                    console.log(this.operatorinfo);
                    if(this.operatorinfo.Type == 2){
                        if(!this.operatorinfo.MerchantID){
                            this.$message.error("请选择商户");
                            return false;
                        }
                    }
                    if(this.operatorinfo.RoleIDs.length <= 0){
                        this.$message.error("请选择账号角色");
                        return false;
                    }
                    
                    this.axios.post(constant.save_operator,qs.stringify(this.operatorinfo),{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        params:{
                            ismerchant:this.ismerchant,
                            mid:this.mid,
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                            this.edit = false;
                            this.$router.go(0);
                        }else if(response.data == "DUPICATE"){
                            this.$message.error("该手机号已存在");
                            return false;
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        Edit(id){
            this.axios.get(constant.get_operator,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.operatorinfo = response.data;
                this.edit = true;
            });
        },
        init(){

            this.axios.get(constant.operator_list,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    keyword: this.keyword,
                    roleid: this.roleid,
                    type:this.type,
                    merchantid:this.merchantid,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        UpdateStatus(id,status,index){
            this.axios.get(constant.update_status,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    id: id,
                    status: status,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                }else if(response.data == "NOROLE"){
                    this.$message.error("该员工目前没有账号角色，请先编辑");
                    this.tabledata[index].Status = '0';
                    return false;
                }
            })
        },
        SavePassword(){
            this.$refs['passwordinfo'].validate((valid)=>{
                if(valid){
                    this.axios.post(constant.save_password,this.passwordinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                            this.password= false;
                        }
                    })
                }else{
                    return false;
                }
            })
        },
        Del(id){
            this.$confirm("确定要删除该账号么？","提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.axios.get(constant.delop,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }
                });
            }).catch(()=>{
                return false;
            })
        },
        GetRoleList(type){
            console.log(type);
           this.axios.get(constant.get_role_list,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    type:type,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.roles = response.data;
                this.roleid = '';
                this.operatorinfo.RoleIDs = [];
            });
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.GetRoleList();
        this.init();
        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            //console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    }
}
</script>